<template>
	<form class="wizard-shipping" @submit.prevent.stop="submitForm(cart)">
		<h3 class="text-center section-title">SHIPPING INFORMATION</h3>

		<div class="text-center col-md-12 line-lg">
			<container-cta></container-cta>
		</div>

		<div class="wizard-shipping-questions row">
			<div class="form-group col-sm-8">
				<label for="full_name">Full Name <small>Required</small></label>
				<input type="text" class="form-control" id="full_name" name="fullName" v-model="cart.shipping.fullName" placeholder="First and Last Name" required autocompleted="name" data-ng-blur="wizardShipping.inlineUpdate()">
			</div>

			<div class="form-group col-sm-4" data-ng-class="{'has-error':form.company.$invalid && (form.company.$touched || wizardShipping.isSubmitted)}">
				<label for="company">Business Name <small>Required</small></label>
				<input type="text" class="form-control" id="company" name="company" v-model="cart.shipping.company" placeholder="Business Name" required autocomplete="organization" @blur="inlineUpdate(cart)">
			</div>

			<div class="form-group col-sm-8" data-ng-class="{'has-error':form.email.$invalid && (form.email.$touched || wizardShipping.isSubmitted)}">
				<label for="email">Email Address <small>Required</small></label>
				<input type="email" class="form-control" id="email" name="email" v-model="cart.shipping.email" placeholder="you@email.com" required autocomplete="email" @blur="inlineUpdate(cart)">
			</div>

			<div class="form-group col-sm-4" data-ng-class="{'has-error':form.zip.$invalid && (form.zip.$touched || wizardShipping.isSubmitted)}">
				<label for="zip">Zip <small>Required</small></label>
				<input type="text" class="form-control" id="zip" name="zip" v-model="cart.shipping.zip" placeholder="Zip Code" minlength="5" required autocomplete="postal-code" @blur="inlineUpdate(cart)">
			</div>

			<div class="form-group col-sm-4" data-ng-if="cart.totalQuantity > 3">
				<label for="dock">Loading dock on site? <small>Required</small></label>
				<div class="select-group">
					<select class="form-control" name="dock" v-model="cart.shipping.dock" required @blur="inlineUpdate(cart)">
						<option default :value="undefined">&ndash; Please Choose &ndash;</option>
						<option :value="item.value" v-for="(item, key) in selectOptions" :key="key">{{item.label}}</option>
					</select>
				</div>
			</div>

			<div class="form-group col-sm-4" data-ng-if="cart.totalQuantity > 3">
				<label for="forklift">Fork lift on site? <small>Required</small></label>
				<div class="select-group">
					<select class="form-control" name="forklift" v-model="cart.shipping.forklift" required @blur="inlineUpdate(cart)">
						<option default :value="undefined">&ndash; Please Choose &ndash;</option>
						<option :value="item.value" v-for="(item, key) in selectOptions" :key="key">{{item.label}}</option>
					</select>
				</div>
			</div>

			<div class="form-group col-sm-4">
				<label for="type">Residential or Commercial? <small>Required</small></label>
				<div class="select-group">
					<select class="form-control" name="type" v-model="cart.shipping.type" required @blur="inlineUpdate(cart)">
						<option default :value="undefined">&ndash; Please Choose &ndash;</option>
						<option :value="item.value" v-for="(item, key) in typeOptions" :key="key">{{item.label}}</option>
					</select>
				</div>
			</div>

			<div class="form-group col-sm-4">
				<input type="hidden" class="form-control" v-model="cart.shipping.country" value="US">
			</div>

			<div class="form-group col-md-12" v-if="error">
				<div class="alert" :class="{'alert-danger':error}">{{error}}</div>
			</div>

			<div class="form-group col-md-12 text-center">
				<button class="btn btn-primary center-block" type="submit" :disabled="loading">Get Shipping Options</button>
			</div>
		</div>
	</form>
</template>

<script>
// Dependencies
import ContainerCta from '@/components/ContainerCta.vue'

// Component
export default {
	name: 'wizardShipping',
	components: {
		ContainerCta,
	},
	data: () => ({
		selectOptions: [
			{
				value: 'yes',
				label: 'Yes',
			},
			{
				value: 'no',
				label: 'No',
			},
		],
		typeOptions: [
			{
				value: 'commercial',
				label: 'Commercial',
			},
			{
				value: 'residential',
				label: 'Residential',
			},
		],
	}),
	computed: {
		cart() {
			const cart = this.$store.getters['cart/cart']
			cart.shipping = cart.shipping || {}
			return cart
		},
		error() {
			return this.$store.getters['cart/error']
		},
		loading() {
			return this.$store.getters['cart/loading']
		},
	},
	methods: {
		submitForm(cart) {
			this.$store.dispatch('cart/updateShipping', cart.shipping)
				.then(() => {
					this.$router.push({ name: 'step2shipping' })
				})
		},
		inlineUpdate(cart) {
			this.$store.dispatch('cart/updateShipping', cart.shipping)
		},
	},
}
</script>

<style scoped lang="scss">
.wizard-shipping {

	&__methods {
		&-list {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
		}

		&-method {
			flex: 1 0 25%;
			text-align: center;
			margin:0 10px $line;
		}
	}

	&__questions {
		.form-group {
			label {
				display: inline-block;
				width: 100%;
				small {
					color: color_('gray','mids');
					// font-family: 'Open Sans';
					margin-left: 0.5em;
				}
			}
			&.has-error {
				label {
					small {
						color: color_('red');
					}
				}
				.form-control {
					border-color: color_('red');
					box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px color_('red');
				}
			}
		}

	}
}
</style>
