<template>
	<div class="wizard-shipping-methods">
		<div class="wizard-shipping-methods__loading" v-show="loading">
			Loading<span class="loading-hellip"><span>.</span></span>
			<span data-ng-bind-html="wizardShippingOptions.loadingMessage"></span><br>
			<small>(This can take up to a minute. Or more.)</small>
		</div>

		<div class="alert" v-if="error" :class="{'alert-danger':error}">{{error}}</div>
		<div class="wizard-shipping-methods__loading"><a class="" v-if="error" @click="tryAgain()">Try Again?</a></div>

		<div class="wizard-shipping-methods__methods">
			<div class="wizard-shipping-methods__method" v-for="(method, key) in shippingMethods" :key="key">
				<div class="wizard-shipping-methods__method-image">
					<div :class="`sprite sprite-${method.slug}`"></div>
				</div>
				<div class="wizard-shipping-methods__method-title">
					{{method.friendly_name || method.carrier}}
				</div>
				<div class="wizard-shipping-methods__method-description">
					{{method.service_name}} (est. {{deliveryTime(method)}})
				</div>
				<div class="wizard-shipping-methods__method-pricing">
					<span class="wizard-shipping-methods__method-price">
						{{method.price | currency}}
					</span>
				</div>
				<button class="btn btn-primary" @click="setMethod(method)">Choose Method</button>
			</div>
		</div>


	</div>
</template>

<script>
// Dependencies
import { track } from '@/lib/analytics'
import { distanceInWordsToNow } from 'date-fns'

// Component
export default {
	name: 'wizardShippingMethods',
	data: () => ({
		isError: false,
		isMessage: false,
	}),
	computed: {
		cart() {
			return this.$store.getters['cart/cart']
		},
		loading() {
			return this.$store.getters['cart/loading']
		},
		shippingMethods() {
			return this.$store.getters['cart/shippingMethods']
		},
		error() {
			return this.$store.getters['cart/error']
		},
	},
	mounted() {
		this.$store.dispatch('cart/loadShipping', this.cart)
	},
	methods: {
		setMethod(method) {
			this.cart.shipping.method = method
			this.$store.dispatch('cart/updateShipping', this.cart.shipping)
				.then(() => {
					track('ec:setAction', 'checkout_option', {
						step: 3,
						option: method.carrier,
					})
					this.$router.push({ name: 'step3' })
				})
		},
		tryAgain() {
			this.$store.dispatch('cart/loadShipping', this.cart)
		},
		deliveryTime(method) {
			return distanceInWordsToNow(method.est_delivery_date)
		},
	},
}
</script>

<style scoped lang="scss">
.wizard-shipping-methods {

	&__methods {
		align-items: flex-end;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
	}

	&__loading {
		font-size: 20px;
		text-align: center;
	}


	&__method {
		flex: 1 0 25%;
		text-align: center;
		margin:0 10px $line;

		@media screen and (max-width: map-get($grid-breakpoints,'md')) {
			flex: 1 0 45%;
		}

		&-image {
			text-align: center;
			.sprite {
				margin: 0 auto;
			}
		}

		&-title {
			font-family: $font-family-secondary;
			text-transform: uppercase;
			// margin-bottom: $line;
			font-size: 24px;
		}

		&-description {
			font-size: 14px;
			margin-bottom:$line;
		}

		&-pricing {
			margin-bottom: $line;
			color:color_('gray','medium');
			font-family: $font-family-secondary;
			font-weight: 300;

			span {
				text-transform: uppercase;
				font-size: 24px;
				color:color_('gray','medium');
				font-weight: 400;
			}
		}
	}
}

$shippers: (
	'aaa_cooper' : $aaa-cooper,
	'central_transport' : $central-transport,
	'conway' : $conway,
	'estes' : $estes,
	'fedex' : $fedex,
	'fedex_freight' : $fedex-freight,
	'randl' : $randl,
	'roadrunner' : $roadrunner,
	'roadrunner_dawes_freight_systems_inc_' : $roadrunner-dawes-freight-systems-inc-,
	'southeastern_freight' : $southeastern-freight,
	'ups' : $ups,
	'ups_freight' : $ups-freight,
	'usps' : $usps,
	'yrc_freight' : $yrc-freight,
	'yrc' : $yrc-freight
);

@each $shipper, $name in $shippers {
	.sprite-#{$shipper} {
		@include sprite-2x($name);
	}
}
</style>
