<template>
	<div class="text-center line">
		<a class="btn btn-primary btn-gray line" :href="`${siteUrl}/container-shipping`"><small>Save thousands by ordering full containers of kegs direct to your brewery. <br>Learn More About Container Shipping <i class="icon small" style="position: relative; top: 4px;">arrow_forward</i></small></a>
	</div>
</template>

<script>
export default {
	name: 'containerCta',
	data: () => ({
		siteUrl: process.env.VUE_APP_CONTENT_SITE_URL,
	}),
}
</script>

<style>

</style>
